export const constants = {
    //baseUrl: '' + process.env.URL,
    //baseUrl: 'https://dev-vardi.bio.credit/api',
    baseUrl: 'https://app-vardi.bio.credit/api',
    //baseUrl: 'http://localhost:8000/api',

    endPoints: {
        settings: '/settings',
        storeContact: '/store-contact',
        isRegistered: '/is-registered',
        paymentRequest: '/pay',
        validateDocuments: '/validate-documents',
        registerUser: '/register-user',
        login: '/login',
        user: '/user',
        form: '/form',
        validateFullForm: '/validate-full-form',
        saveForm: '/save-field-value',
        policies: '/insurance-policies',
        simulator: '/simulator',
        phoneIsRegistered: '/phone-is-registered',
        registerPhone: '/register-phone',
        validatePhone: '/validate-phone',
        createRequest: '/create-request',
        signDocuments: '/sign-documents',
        signDocument: '/sign-document',
        reSendSignatureOtp: '/re-send-signature-otp',
        startPayment: '/start-payment',
        manualValidateDocuments: '/manual-validate-documents',
    },

    paymentDays: [
        {
            minDay: 1,
            maxDay: 7,
            day: 4
        },
        {
            minDay: 8,
            maxDay: 14,
            day: 8
        },
        {
            minDay: 15,
            maxDay: 21,
            day: 15
        },
        {
            minDay: 22,
            maxDay: 31,
            day: 22
        }
    ]
};
